import Api from "api/Api";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const navigation = useHistory()

  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [api, setApi] = useState([])
  const [ddApi, setDdApi] = useState('0')

  useEffect(() => {
    const options = {
      method: 'GET',
      url: Api.url,
      params: { a: 'api' }
    };

    axios.request(options).then(function (response) {
      console.log(response.data);
      if (response.data.cek === 1) {
        setApi(response.data.data)
      }
    }).catch(function (error) {
      console.error(error);
    });
  }, [])

  const Login = () => {
    if (ddApi === '0') {
      alert('harap pilih lokasi!')
    } else {
      const options = {
        method: 'POST',
        url: Api.url,
        params: { a: 'login' },
        headers: { 'Content-Type': 'application/json' },
        data: { email: email, password: password }
      };

      axios.request(options).then(function (response) {
        console.log(response.data);
        if (response.data.cek == 0) {
          return
        } else {
          dispatch({
            type: "LOGIN",
            isLogin: true,
            payload: response.data.data,
            level: ddApi,
            status: api,
            // token: token.token
          })
          // dispatch({ type: "REMOVE_ALL" })

          // navigation.push('')
        }
      }).catch(function (error) {
        console.error(error);
      });
    }

  }

  const onLogout = () => {
    dispatch({ type: 'LOGIN', isLogin: true })
    navigation.goBack()
  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                {/* <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with
                  </h6>
                </div> */}
                <div className="btn-wrapper text-center">
                  {/* <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg").default}
                    />
                    Github
                  </button> */}
                  {/* <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button> */}
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/* <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div> */}
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Lokasi
                    </label>
                    <select
                      value={ddApi}
                      onChange={(e) => setDdApi(e.target.value)}
                      type="select"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                      <option value="0" >Pilih lokasi</option>
                      {api?.map((item, i) => (
                        <option key={i} value={item.api} >{item.nama}</option>
                      ))}


                    </select>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                  <div>
                    {/* <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label> */}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      onClick={() => Login()}
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              {/* <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div> */}
              <div className="w-1/2 text-right">
                {/* <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
