import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { createPopper } from "@popperjs/core";
import PaginationComponent from 'components/Pagination';
import { useSelector } from 'react-redux';

const Produk = () => {
    let history = useHistory();
    const [filePath, setFilePath] = useState([]);
    const api = useSelector(state => state.userReducer.level)
    const [produk, setProduk] = useState([])
    const [barang, setBarang] = useState([])
    const [text, setText] = useState('')
    const [buka, setBuka] = useState(false)
    const [subkat, setSubkat] = useState([])
    const [searchQuery, setSearchQuery] = React.useState('');
    const [searchQuery2, setSearchQuery2] = React.useState('');
    const [kategori, setKategori] = useState([])
    const [sets, setSets] = useState(false)
    const [id, setId] = useState('')
    const [kobar, setkobar] = useState('')
    const [nabar, setnabar] = useState('')
    const [satuan, setsatuan] = useState('')
    const [harpok, setharpok] = useState('0')
    const [harjul, setharjul] = useState('0')
    const [harjul_grosir, setharjul_grosir] = useState('0')
    const [stok, setstok] = useState('0')
    const [min_stok, setmin_stok] = useState('')
    const [kat, setkat] = useState('0')
    const [id_sub_kategori, setid_sub_kategori] = useState('')
    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loading, setLoading] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);

    const pageSize = 10;

    const onChangeSearch = query => setSearchQuery(query);

    const [visible, setVisible] = React.useState(false);
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);

    const openMenu = () => setVisible2(true);

    const closeMenu = () => setVisible2(false);


    useEffect(() => {
        getKategori()
        getProduk()
        // getSub()
    }, [])

    const getKategori = () => {

        const options = { method: 'GET', url: api, params: { a: 'kategori' } };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setKategori(response.data.data);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const getSub = () => {
        // item.preventDefault();
        // openDropdownPopover();
        // setId(item.kategori_id)
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'subkategori2', sub: searchQuery2 }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setSubkat(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
    }

    const onKat = e => {

        setSearchQuery2(e.target.value);
        setid_sub_kategori('')
        console.log(e.target.value)
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'subkategori2', sub: e.target.value }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setSubkat(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
        // const options = { method: 'GET', url: api + 'categories/subkat/' + e.target.value };

        // axios.request(options).then(function (response) {
        //     console.log(response.data);
        //     setSubkat(response.data.response)
        // }).catch(function (error) {
        //     console.error(error);
        // });
    }

    const getProduk = () => {


        const options = { method: 'GET', url: api, params: { a: 'barang' } };

        axios.request(options).then(function (response) {
            console.log('prod',response.data.data);
            setBarang(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
    }

    const commentsData = useMemo(() => {
        // console.log(search)
        let computedComments = barang;

        if (searchQuery) {
            computedComments = computedComments.filter(
                comment =>
                    comment.nama_barang.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    comment.kode_barang.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (id_sub_kategori) {
            computedComments = computedComments.filter(
                comment =>
                    // comment.id_sub_kategori.toLowerCase().includes(id_sub_kategori.toLowerCase())||
                    comment.subkat.toLowerCase().includes(id_sub_kategori.toLowerCase())

            );
        }

        if (searchQuery2) {
            computedComments = computedComments.filter(
                comment =>
                    comment.kat.toLowerCase().includes(searchQuery2.toLowerCase())

            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
        );
    }, [barang, currentPage, searchQuery, searchQuery2, id_sub_kategori, sorting]);

    function currencyFormat(num) {
        return 'Rp ' + parseFloat(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function handleClick() {
        history.push("/admin/produk/tambah-produk");
    }

    const handleClickEdit = (item) => {
        history.push("/admin/produk/edit-produk/" + item.id_barang);
        // setFilePath(sorce => [...sorce, {
        //     kode_barang: item.kode_barang,
        //     nama_barang: item.nama_barang,
        //     qty: 1
        // }])

        // const indexs = filePath.find(
        //     product => product.kode_barang === item.kode_barang
        // )

        // console.log(indexs)

        // if (indexs) {
        //     setFilePath(
        //         filePath.map((id) => id.kode_barang === item.kode_barang ? {
        //             ...id,
        //             qty: id.qty + 1

        //         } : id

        //         )
        //     )
        // } else {
        //     return
        // }

    }

    const diHapus = (item) => {
        let filteredArray = filePath.filter(items => items.kode_barang !== item.kode_barang)
        setFilePath(filteredArray)
    }

    const HapusItem = (item) => {
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'hapusproduct' },
            headers: { 'Content-Type': 'application/json' },
            data: { kode_barang: item.kode_barang }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            getProduk()

        }).catch(function (error) {
            console.error(error);
        });


        // console.log('cart', this.props.cartItems.length);
    }


    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full mb-12 px-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                            "bg-white"
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center justify-between" >
                                <div className="relative w-full px-4 max-w-full flex-grow  flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            "text-blueGray-700"
                                        }
                                    >
                                        Data Produk
                                    </h3>

                                </div>
                                <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                                    <div className="relative flex w-full flex-wrap items-stretch">
                                        {/* <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Kategori
                                    </label> */}

                                        <select value={searchQuery2} onChange={onKat} className="border-0 px-6 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option value={''}>Pilih Kategori</option>
                                            {kategori?.map((item, i) => (
                                                <option key={i} value={item.nama_kategori}    >{item.nama_kategori}</option>
                                            ))}


                                        </select>
                                    </div>
                                </form>
                                <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                                    <div className="relative flex w-full flex-wrap items-stretch">
                                        {/* <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Kategori
                                    </label> */}

                                        <select value={id_sub_kategori} onChange={(e) => setid_sub_kategori(e.target.value)} className="border-0 px-6 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <option>Pilih SubKategori</option>
                                            {subkat?.map((sub, i) => (
                                                <option key={i} value={sub.nama_sub_kategori}>{sub.nama_sub_kategori}</option>
                                            ))}
                                        </select>
                                    </div>
                                </form>
                                <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                                    <div className="relative flex w-full flex-wrap items-stretch">
                                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-4 py-2">
                                            <i className="fas fa-search"></i>
                                        </span>
                                        <input
                                            value={searchQuery} onChange={(e) => onChangeSearch(e.target.value)}
                                            type="text"
                                            placeholder="Cari..."
                                            className="border-1 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                                        />
                                    </div>
                                </form>
                                {/* <div class=" flex flex-wrap items-stretch ">
                                    
                                    <input value={searchQuery} onChange={(e) => onChangeSearch(e.target.value)} type="text" placeholder="Cari ..." className="px-4 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline  mr-1 mb-1" />
                                </div> */}
                                <button
                                    onClick={handleClick}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-plus"></i> Tambah Produk
                                </button>

                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                            {/* {filePath?.map((item, i) => (

                                <button key={i} onClick={() => diHapus(item)}

                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-plus"></i>{item.nama_barang} - {item.qty}
                                </button>
                            ))} */}
                            {/* Projects table */}
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Gambar
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Nama
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Harga Pokok
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Harga Jual
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Diskon
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Stok
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Action
                                        </th>

                                    </tr>
                                </thead>



                                <tbody>
                                    {commentsData?.map((item, i) => (
                                        <tr key={i}>
                                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                                <img
                                                    src={item.foto}
                                                    className="h-12 w-12 bg-white rounded-full border"
                                                    alt="..."
                                                ></img>{" "}
                                                <span
                                                    className={
                                                        "ml-3 font-bold text-blueGray-600 "
                                                        // +(color === "light" ? "text-blueGray-600" : "text-white")
                                                    }
                                                >

                                                </span>
                                            </th>
                                            {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.foto}
                                            </td> */}
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.nama_barang}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {currencyFormat(item.harga_modal)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {currencyFormat(item.harga_jual)}
                                            </td>

                                            {/* {item.diskon == '' ?
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {currencyFormat(item.diskon_nominal)}
                                                </td>
                                                : */}
                                            {item.diskon == '' && item.diskon_nominal == null ?
                                                <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> - </td>
                                                :
                                                <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item.diskon == '' ? currencyFormat(item.diskon_nominal) : item.diskon + '%'}
                                                </td>}
                                            {/* } */}

                                            <td className="border-t-0 px-6 r align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.stok}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <button
                                                    onClick={() => handleClickEdit(item)}
                                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-pen"></i> Edit
                                                </button>


                                                <button
                                                    onClick={() => HapusItem(item)}
                                                    className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-trash"></i> Hapus
                                                </button>
                                            </td>


                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                        </div>

                    </div>
                    <PaginationComponent
                        total={totalItems}
                        itemsPerPage={pageSize}
                        currentPage={currentPage}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>

            </div>
        </>
    )
}

export default Produk