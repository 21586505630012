import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';

const Retur = () => {
    let history = useHistory();
    const [allOrder, setAllOrder] = useState([])
    const api = useSelector(state => state.userReducer.level)



    useEffect(() => {
        getAllOrder()
    }, [])

    const getAllOrder = () => {
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'gettransaksiRn' }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.cek === 1) {

                setAllOrder(response.data.data)
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    function handleClickEdit(item) {
        history.push("/admin/retur/det-order/" + item.nofaktur);
        // console.log(item)
    }

    const columns = [
        {
            name: 'No Faktur',
            selector: row => row.nofaktur,
        },
        {
            name: 'Tanggal',
            selector: row => moment(row.tanggal).format('DD - MM - YYYY, HH:mm'),
        },
        {
            name: 'Jumlah Item',
            selector: row => row.jumlahitem,
        },
        {
            name: 'Action',
            selector: row => <button
                onClick={() => handleClickEdit(row)}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                <i className="fas fa-redo"></i> Retur
            </button>,
        },
    ];

    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full mb-12 px-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                            "bg-white"
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center justify-between" >
                                <div className="relative w-full px-4 max-w-full flex-grow  flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            "text-blueGray-700"
                                        }
                                    >
                                        Data Order
                                    </h3>

                                </div>


                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                            {/* Projects table */}
                            <DataTable columns={columns} data={allOrder} persistTableHead pagination />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Retur