import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const TambahKaryawan = () => {
    let history = useHistory();
    let ids = useParams();
    const api = useSelector(state => state.userReducer.level)
    const [kategori, setKategori] = useState([])
    const [subkat, setSubkat] = useState([])
    const [sets, setSets] = useState(false)
    const [id, setId] = useState('')
    const [nama, setnama] = useState('')
    const [nohp, setnohp] = useState('')
    const [status, setstatus] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [alamat, setalamat] = useState('')
    const [harga_jual, setharga_jual] = useState('')
    const [stok, setstok] = useState('')
    const [foto, setfoto] = useState('')
    const [id_sub_kategori, setid_sub_kategori] = useState('0')
    const [na, setna] = useState('0')

    const [gambar, setGambar] = useState('image/jpg')
    const [images, setImages] = useState()
    const [fileName, setFileName] = useState("placeholder-image.jpg");
    const [preview, setPreview] = useState('')
    const [loading, setLoading] = useState(false);

    const [produk, setProduk] = useState([])


    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });


    const [searchQuery, setSearchQuery] = React.useState('');


    const onChangeSearch = (query) => {
        setSearchQuery(query.kategori_nama);
    }

    const pageSize = 100;
    // console.log('ids', ids);
    useEffect(() => {
        // getKategori()
        getKaryawanId()
        // getSub()
    }, [])






    // const getSub = () => {
    //     // item.preventDefault();
    //     // openDropdownPopover();
    //     // setId(item.kategori_id)
    //     const options = {
    //         method: 'GET',
    //         url: api,
    //         params: { a: 'subkategori', sub: searchQuery }
    //     };

    //     axios.request(options).then(function (response) {
    //         console.log(response.data);
    //         setSubkat(response.data.data)
    //     }).catch(function (error) {
    //         console.error(error);
    //     });
    // }

    // const getSub = (item) => {
    //     // item.preventDefault();
    //     dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
    //     setId(item.kategori_id)
    //     const options = { method: 'GET', url: api + 'categories/subkat/' + item.kategori_id };

    //     axios.request(options).then(function (response) {
    //         // console.log(response.data);
    //         setSubkat(response.data.response)
    //     }).catch(function (error) {
    //         console.error(error);
    //     });
    // }

    useEffect(() => {
        if (!images) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(images)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [images])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImages(undefined)
            return
        }
        console.log(e.target.files[0])
        // I've kept this example simple by using the first image instead of multiple
        setImages(e.target.files[0])
    }

    function handleClick() {
        history.goBack();
    }

    const getKaryawanId = () => {
        if (ids.id == undefined) {
            setSets(false)

        } else {
            setSets(true)
            const options = {
                method: 'GET',
                url: api,
                params: { a: 'karyawanid', id: ids.id }
            };

            axios.request(options).then(function (response) {
                console.log(response.data.data);
                // setProduk(response.data.response)
                setnama(response.data.data[0].nama)
                setnohp(response.data.data[0]?.nohp)
                setalamat(response.data.data[0]?.alamat)
                setemail(response.data.data[0]?.email)
                setpassword(response.data.data[0]?.password)
                setstatus(response.data.data[0]?.status)

                // setGambar('image/jpg')
                // setSearchQuery(response.data.response[0]?.kategori_nama)
                // getSub()
                setPreview(response.data.data[0]?.foto)
                // setFileName(response.data.data[0]?.foto)
            }).catch(function (error) {
                console.error(error);
            });
        }

    }

    const onKat = e => {

        setSearchQuery(e.target.value);
        console.log(e.target.value)
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'subkategori', sub: e.target.value }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setSubkat(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
        // const options = { method: 'GET', url: api + 'categories/subkat/' + e.target.value };

        // axios.request(options).then(function (response) {
        //     console.log(response.data);
        //     setSubkat(response.data.response)
        // }).catch(function (error) {
        //     console.error(error);
        // });
    }

    // const commentsData = useMemo(() => {
    //     let computedComments = subkat;

    //     if (searchQuery) {
    //         computedComments = computedComments.filter(
    //             comment =>
    //                 comment.kategori_nama.toLowerCase().includes(searchQuery.toLowerCase())
    //         );
    //     }
    //     setkat(computedComments[0]?.sub_kategori_id)
    //     // setkate(e.target.value)
    //     console.log(computedComments[0]?.sub_kategori_id)

    //     setTotalItems(computedComments.length);

    //     //Sorting comments
    //     if (sorting.field) {
    //         const reversed = sorting.order === "asc" ? 1 : -1;
    //         computedComments = computedComments.sort(
    //             (a, b) =>
    //                 reversed * a[sorting.field].localeCompare(b[sorting.field])
    //         );
    //     }

    //     //Current Page slice
    //     return computedComments.slice(
    //         (currentPage - 1) * pageSize,
    //         (currentPage - 1) * pageSize + pageSize
    //     );
    // }, [subkat, currentPage, searchQuery, sorting]);

    // const onKate = (e) => {
    //     setkate(e.target.value)
    //     console.log(e.target.value)
    // }

    const postKaryawan = () => {
        // if (kodebarang.length === 0 || namabarang.length === 0 || id_kategori.length === 0 || satuan.length === 0 || harpok.length === 0 || harjul.length === 0 || stok.length === 0) {
        //     return
        // }
        setLoading(true)
        // const form = new FormData();
        // form.append("fileData", images);
        const form = new FormData();
        form.append("nama", nama);
        form.append("nohp", nohp);
        form.append("status", status);
        form.append("email", email);
        form.append("password", password);
        form.append("alamat", alamat);
        form.append("image", images);



        const options = {
            method: 'POST',
            url: api,
            params: { a: 'addkaryawan' },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form
        };
        console.log('form', form);
        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.ok == false) {
                setLoading(false)

                return
            } else {
                setLoading(false)
                // setText('')
                history.goBack()
            }
        }).catch(function (error) {
            console.error(error);
        });
        // console.log('gbr', gambar.uri)
        // const formdata = new FormData();
        // formdata.append("kobar", kobar);
        // formdata.append("nabar", nabar);
        // formdata.append("satuan", satuan);
        // formdata.append("harpok", Number(harpok));
        // formdata.append("harjul", Number(harjul));
        // formdata.append("harjul_grosir", Number(harjul_grosir));
        // formdata.append("stok", Number(stok));
        // formdata.append("min_stok", Number(min_stok));
        // formdata.append("kat", kat);
        // formdata.append("kate", kate);
        // formdata.append("user_id", 1);
        // formdata.append("fileData", images);
        // const config = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     body: formdata,
        // };
        // fetch(api + "products/tambahprod", config)
        //     .then((checkStatusAndGetJSONResponse) => {
        //         console.log(checkStatusAndGetJSONResponse);
        //         if (checkStatusAndGetJSONResponse.ok == false) {
        //             setLoading(false)
        //             return
        //         } else {
        //             setLoading(false)
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //         setLoading(false)
        //     });

    }

    const editKaryawan = () => {


        const form = new FormData();
        form.append("userid", ids.id);
        form.append("nama", nama);
        form.append("nohp", nohp);
        form.append("status", status);
        form.append("email", email);
        form.append("password", password);
        form.append("alamat", alamat);
        form.append("image", images);

        const options = {
            method: 'POST',
            url: api,
            params: { a: 'editkaryawan' },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form
        };
        console.log('form', form);
        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.ok == false) {
                setLoading(false)
                return
            } else {
                setLoading(false)
                // setText('')
                history.goBack()
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <button onClick={handleClick}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-arrow-left"></i>  Kembali
                        </button>
                        {sets ?
                            <button onClick={editKaryawan}
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button"
                            >
                                <i className="fas fa-check"></i> Simpan Edit
                            </button>
                            :
                            <button onClick={postKaryawan}
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button"
                            >
                                <i className="fas fa-check"></i> Simpan
                            </button>}
                    </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>

                        <div className="flex mt-3  mb-6 flex-wrap">

                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Photo
                                    </label>
                                    <input
                                        type="file"
                                        onChange={onSelectFile}
                                        className="border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // defaultValue="lucky.jesse"
                                    />
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-6/12 sm:w-4/12 px-4">
                                            {images ? <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" /> : <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" />}

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Nama Karyawan
                                    </label>
                                    <input
                                       
                                        type="text"
                                        value={nama}
                                        onChange={(e) => setnama(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Alamat
                                    </label>
                                    <input
                                        type="text"
                                        value={alamat}
                                        onChange={(e) => setalamat(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        No HP
                                    </label>
                                    <input
                                        type="text"
                                        value={nohp}
                                        onChange={(e) => setnohp(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Status
                                    </label>
                                    <select value={status} onChange={(e) => { setstatus(e.target.value); console.log(status) }} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option value=''>Pilih Status</option>
                                        <option value='1'>OWNER</option>
                                        <option value='2'>ADMIN</option>
                                        <option value='3'>KASIR</option>


                                    </select>

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Password
                                    </label>
                                    <input
                                        type="text"
                                        value={password}
                                        onChange={(e) => setpassword(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>






                        </div>

                        <hr className="mt-6 border-b-1 border-blueGray-300" />

                        {/* <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Contact Information
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="email"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="New York"
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Country
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="United States"
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Postal Code
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="Postal Code"
                                    />
                                </div>
                            </div>
                        </div>

                        <hr className="mt-6 border-b-1 border-blueGray-300" />

                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            About Me
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        About me
                                    </label>
                                    <textarea
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="A beautiful UI Kit and Admin for React & Tailwind CSS. It is Free and Open Source."
                                        rows="4"
                                    ></textarea>
                                </div>
                            </div>
                        </div> */}
                    </form>
                </div>
            </div>
        </>
    )
}

export default TambahKaryawan
