import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";

const EditKat = () => {
    let history = useHistory();
    let id = useParams();
    const api = useSelector(state => state.userReducer.level)
    const [kat, setKat] = useState([])
    const [text, setText] = useState('')
    const [images, setImages] = useState()
    const [preview, setPreview] = useState('')

    console.log('route', id)
    useEffect(() => {
        getEdit()
    }, [])

    const getEdit = () => {
        const options = { method: 'GET', url: api + 'categories/' + id.id };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setKat(response.data.response)
            setText(response.data.response[0].kategori_nama)
            // handleClick()
        }).catch(function (error) {
            console.error(error);
        });
    }

    function handleClick() {
        history.goBack();
    }

    const editKategori = () => {
        console.log(images)
        if (images === undefined) {
            var formdata = new FormData();
            formdata.append("nama_kategori", text);
            var requestOptions1 = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch(api + "categories/edit/" + id.id, requestOptions1)
                .then(response => response.text())
                .then(result => (

                    handleClick()

                ))

                .catch(error => console.log('error', error));
        } else {
            var formdata = new FormData();
            formdata.append("fileData", images);
            formdata.append("nama_kategori", text);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch(api + "categories/edit/" + id.id, requestOptions)
                .then(response => response.text())
                .then(result => (

                    handleClick()

                ))
                .catch(error => console.log('error', error));
        }





        // const form = new FormData();
        // form.append("fileData", images);
        // form.append("nama_kategori", text);
        // const config = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     body: form,
        // };
        // fetch(api + "categories/edit/" + id.id, config)
        //     .then((checkStatusAndGetJSONResponse) => {
        //         console.log(checkStatusAndGetJSONResponse);
        //         if (checkStatusAndGetJSONResponse.ok == false) {

        //             return
        //         } else {

        //             getEdit()
        //             setText('')
        //             handleClick()
        //         }
        //     }).catch((err) => { console.log(err) });

    }

    useEffect(() => {
        if (!images) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(images)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [images])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImages(undefined)
            return
        }
        console.log(e.target.files[0])
        // I've kept this example simple by using the first image instead of multiple
        setImages(e.target.files[0])
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <button onClick={handleClick}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-arrow-left"></i>  Kembali
                        </button>
                        <button onClick={editKategori}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-check"></i> Simpan
                        </button>
                    </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Tambah Kategori
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">

                                    <input
                                        type="file"
                                        onChange={onSelectFile}
                                        className="border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-6/12 sm:w-4/12 px-4">
                                            {images ? <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" /> : <img src={kat[0]?.gbr} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" />}
                                            {/* {images && <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" />} */}

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4"></div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Nama
                                    </label>
                                    <input
                                        type="text"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                        placeholder='nama'
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // defaultValue="jesse@example.com"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditKat