import Api from 'api/Api';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const DetRetur = () => {
    let ids = useParams();
    const [detOrder, setDetOrder] = useState([])
    const api = useSelector(state => state.userReducer.level)

    useEffect(() => {
        getDetOrder()
    }, [])

    const getDetOrder = (item) => {
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'transaksiid', id: ids.id }
        };

        axios.request(options).then(function (response) {
            console.log('detail', response.data);
            setDetOrder(response.data.data)

        }).catch(function (error) {
            console.error(error);
        });
    }

    function currencyFormat(num) {
        return 'Rp ' + parseFloat(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    const postRetur = (item) => {
        let diBayar = [];
        diBayar.push({
            nofaktur: item.nofak,
            kode_barang: item.kode_barang,
            nama_barang: item.nama_barang,
            harga_modal: item.harga_modal,
            harga_jual: item.harga_jual,
            qty: item.qty,
            diskon: item.diskon,
            diskon_nominal: item.diskon_nominal,
            jual_diskon: item.jual_diskon,
            totalbayardiskon: (item.totalbayar - item.jual_diskon),
            diskon: '',
            bayar: item.totalbayar,
            kembali: item.jual_diskon,

        })
        console.log(item)
        console.log((item.totalbayar - item.jual_diskon))
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'retur' },
            headers: { 'Content-Type': 'application/json' },
            data: {

                data: diBayar
            }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.Pesan == "Berhasil") {
                alert("Berhasil di Retur ")
                getDetOrder()

            } else {
                return
            }
        }).catch(function (error) {
            console.error(error);
        });

    }

    const columns = [
        {
            name: 'Nama Barang',
            selector: row => row.nama_barang,
        },
        {
            name: 'Tanggal',
            selector: row => moment(row.jual_tanggal).format('DD - MM - YYYY'),
        },
        {
            name: 'Harga Jual',
            selector: row => currencyFormat(row.harga_jual),
        },
        {
            name: 'Diskon',
            selector: row => row.diskon == '' && row.diskon_nominal == null ? '-' :
                row.diskon == '' ? currencyFormat(row.diskon_nominal) : row.diskon + '%'
        },
        {
            name: 'Qty',
            selector: row => row.qty,
        },
        {
            name: ' SUB Total',
            selector: row => currencyFormat(row.jual_diskon),
        },
        {
            name: 'Action',
            selector: row => <button
                onClick={() => postRetur(row)}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                <i className="fas fa-redo"></i> Retur
            </button>,
        },
    ];

    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full mb-12 px-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                            "bg-white"
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center justify-between" >
                                <div className="relative w-full px-4 max-w-full flex-grow  flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            "text-blueGray-700"
                                        }
                                    >
                                        No Faktur {ids.id}
                                    </h3>

                                </div>
                            </div>

                        </div>
                        <div className="block w-full overflow-x-auto">
                            <DataTable columns={columns} data={detOrder} persistTableHead pagination />
                            {/* Projects table */}


                        </div>
                    </div>
                </div>
                {/* <PaginationComponent
                    total={totalItems}
                    itemsPerPage={pageSize}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                /> */}
            </div>
        </>
    )
}

export default DetRetur;