import React from 'react'
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

const Router = () => {
    const login = useSelector(state => state.userReducer)
    return (
        <BrowserRouter>

            {/* add routes with layouts */}
            {login.isLogin == false ?
                <Switch>
                    <Route path="/auth" exact component={Auth} />
                    <Route path="/auth" component={Auth} />
                    {/* add redirect for first page */}
                    <Redirect from="*" to="/auth" />

                </Switch>
                :
                <Switch>
                    {/* <Route path="/" exact component={Index} /> */}
                    <Route path="/admin" component={Admin} />
                    {/* add routes without layouts */}
                    {/* <Route path="/landing" exact component={Landing} /> */}
                    {/* <Route path="/profile" exact component={Profile} /> */}

                    {/* add redirect for first page */}
                    <Redirect from="*" to="/admin" />
                </Switch>


            }


        </BrowserRouter>
    )
}

export default Router