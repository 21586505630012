import React from 'react'
// layouts



import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import Router from 'Router';
import persist from '../src/redux/store'

const persistStore = persist();

const App = () => {
    // const login = useSelector(state => state.userReducer)
    return (
        <StoreProvider store={persistStore.store} >
            <PersistGate loading={null} persistor={persistStore.persistor}>
                <Router />
            </PersistGate>
        </StoreProvider>
    )
}

export default App