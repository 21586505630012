import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { createPopper } from "@popperjs/core";
import { useSelector } from 'react-redux';

const Karyawan = () => {
    let history = useHistory();
    const [filePath, setFilePath] = useState([]);
    const api = useSelector(state => state.userReducer.level)
    // const login = useSelector(state => state.userReducer)
    const [produk, setProduk] = useState([])
    const [barang, setBarang] = useState([])
    const [text, setText] = useState('')
    const [buka, setBuka] = useState(false)

    const [searchQuery, setSearchQuery] = React.useState('');

    const [kategori, setKategori] = useState([])
    const [karyawan, setKaryawan] = useState([])
    const [sets, setSets] = useState(false)
    const [id, setId] = useState('')
    const [kobar, setkobar] = useState('')
    const [nabar, setnabar] = useState('')
    const [satuan, setsatuan] = useState('')
    const [harpok, setharpok] = useState('0')
    const [harjul, setharjul] = useState('0')
    const [harjul_grosir, setharjul_grosir] = useState('0')
    const [stok, setstok] = useState('0')
    const [min_stok, setmin_stok] = useState('')
    const [kat, setkat] = useState('0')

    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loading, setLoading] = useState(false);

    const pageSize = 20;

    const onChangeSearch = query => setSearchQuery(query);

    const [visible, setVisible] = React.useState(false);
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);

    const openMenu = () => setVisible2(true);

    const closeMenu = () => setVisible2(false);

    useEffect(() => {
        getKaryawan()
    }, []);

    const getKategori = () => {

        const options = { method: 'GET', url: api + 'categories' };

        axios.request(options).then(function (response) {
            // console.log(login);

            setKategori(response.data.response)

        }).catch(function (error) {
            console.error(error);
        })
    }

    const getKaryawan = () => {


        const options = { method: 'GET', url: api, params: { a: 'karyawan' } };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setKaryawan(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
    }

    const commentsData = useMemo(() => {
        // console.log(search)
        let computedComments = produk;

        if (searchQuery) {
            computedComments = computedComments.filter(
                comment =>
                    comment.barang_nama.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    comment.barang_id.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.kategori_nama.toLowerCase().includes(search.toLowerCase())

            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
        );
    }, [produk, currentPage, searchQuery, search, sorting]);

    function currencyFormat(num) {
        return 'Rp ' + parseFloat(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function handleClick() {
        history.push("/admin/karyawan/tambah-karyawan");
    }

    function handleClickEdit(item) {
        history.push("/admin/karyawan/edit-karyawan/" + item.userid);
        // console.log(item)
    }

    const HapusItem = (item) => {
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'hapuskaryawan' },
            headers: { 'Content-Type': 'application/json' },
            data: { userid: item.userid }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            getKaryawan()

        }).catch(function (error) {
            console.error(error);
        });


        // console.log('cart', this.props.cartItems.length);
    }


    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full mb-12 px-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                            "bg-white"
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center justify-between" >
                                <div className="relative w-full px-4 max-w-full flex-grow  flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            "text-blueGray-700"
                                        }
                                    >
                                        Data Karyawan
                                    </h3>

                                </div>
                                <button
                                    onClick={handleClick}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-plus"></i> Tambah Karyawan
                                </button>

                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                            {/* Projects table */}
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Gambar
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Nama
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Alamat
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            No Hp
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Action
                                        </th>

                                    </tr>
                                </thead>



                                <tbody>
                                    {karyawan?.map((item, i) => (
                                        <tr key={i}>
                                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                                <img
                                                    src={item.foto}
                                                    className="h-12 w-12 bg-white rounded-full border"
                                                    alt="..."
                                                ></img>{" "}
                                                <span
                                                    className={
                                                        "ml-3 font-bold text-blueGray-600 "
                                                        // +(color === "light" ? "text-blueGray-600" : "text-white")
                                                    }
                                                >

                                                </span>
                                            </th>
                                            {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.foto}
                                            </td> */}
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.nama}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.alamat}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.nohp}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <button
                                                    onClick={() => handleClickEdit(item)}
                                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-pen"></i> Edit
                                                </button>


                                                <button
                                                    onClick={() => HapusItem(item)}
                                                    className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-trash"></i> Hapus
                                                </button>
                                            </td>


                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Karyawan