import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { createPopper } from "@popperjs/core";
import PaginationComponent from 'components/Pagination';
import { useSelector } from 'react-redux';

const Lapjualbulan = () => {
    let history = useHistory();
    const [filePath, setFilePath] = useState([]);
    const api = useSelector(state => state.userReducer.level)
    // const login = useSelector(state => state.userReducer)
    const [produk, setProduk] = useState([])
    const [date, setDate] = useState('')
    const [text, setText] = useState('')
    const [buka, setBuka] = useState(false)

    const [searchQuery, setSearchQuery] = React.useState('');

    const [kategori, setKategori] = useState([])
    const [sets, setSets] = useState(false)
    const [id, setId] = useState('')
    const [kobar, setkobar] = useState('')
    const [nabar, setnabar] = useState('')
    const [satuan, setsatuan] = useState('')
    const [harpok, setharpok] = useState('0')
    const [harjul, setharjul] = useState('0')
    const [harjul_grosir, setharjul_grosir] = useState('0')
    const [stok, setstok] = useState('0')
    const [min_stok, setmin_stok] = useState('')
    const [kat, setkat] = useState('0')
    const [bulan, setBulan] = useState([])
    const [cariBulan, setCariBulan] = useState('')

    const [transaksi, setTransaksi] = useState([])
    const [totaljual, setTotaljual] = useState([])
    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loading, setLoading] = useState(false);

    const pageSize = 10;

    const onChangeSearch = query => setSearchQuery(query);

    const [visible, setVisible] = React.useState(false);
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);

    const openMenu = () => setVisible2(true);

    const closeMenu = () => setVisible2(false);


    useEffect(() => {
        // getKategori()
        // getProduk()

        getTransaksi()
        getTotaljual()
        // getBulan()
    }, [])

    // const getKategori = () => {

    //     const options = { method: 'GET', url: api + 'categories' };

    //     axios.request(options).then(function (response) {
    //         // console.log(login);

    //         setKategori(response.data.response)

    //     }).catch(function (error) {
    //         console.error(error);
    //     })
    // }

    const getTransaksi = () => {

        const options = {
            method: 'GET',
            url: api,
            params: { a: 'getTotalJUalbulan', bln: '' }
        };

        axios.request(options).then(function (response) {
            // console.log(login);

            setTransaksi(response.data.data)

        }).catch(function (error) {
            console.error(error);
        })
    }

    const getTransaksibulan = () => {

        const options = {
            method: 'GET',
            url: api,
            params: { a: 'getTotalJUalbulan', bln: cariBulan }
        };

        axios.request(options).then(function (response) {
            // console.log(login);

            setTransaksi(response.data.data)

        }).catch(function (error) {
            console.error(error);
        })
    }

    const gettotalBulan = () => {
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'getTotalBulan', tgl: cariBulan }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setTotaljual(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
    }

    const onGetJualBulan = () => {
        getTransaksibulan()
        gettotalBulan()
        setCurrentPage(1)
    }

    const getTotaljual = () => {

        const options = {
            method: 'GET',
            url: api,
            params: { a: 'getTotalJUal', tgl: '' }
        };

        axios.request(options).then(function (response) {
            // console.log(login);

            setTotaljual(response.data.data)

        }).catch(function (error) {
            console.error(error);
        })
    }

    const getProduk = () => {
        const options = { method: 'GET', url: api + 'products' };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setProduk(response.data.response)
        }).catch(function (error) {
            console.error(error);
        });
    }

    const commentsData = useMemo(() => {
        // console.log(search)
        let computedComments = transaksi;

        // if (searchQuery) {
        //     computedComments = computedComments.filter(
        //         comment =>
        //             comment.barang_nama.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //             comment.barang_id.toLowerCase().includes(searchQuery.toLowerCase())
        //     );
        // }

        // if (search) {
        //     computedComments = computedComments.filter(
        //         comment =>
        //             comment.kategori_nama.toLowerCase().includes(search.toLowerCase())

        //     );
        // }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
        );
    }, [transaksi, currentPage, searchQuery, search, sorting]);

    const getReset = () => {
        getTransaksi()
        getTotaljual()
        setDate(date)

    }

    function currencyFormat(num) {
        return 'Rp ' + parseFloat(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function handleClick() {
        history.push("/admin/lapjualbulan/laporan-bulan/" + cariBulan);
    }

    // function handleClickEdit(item) {
    //     history.push("/admin/produk/edit-produk/" + item.barang_id);
    // }

    // const HapusItem = (item) => {
    //     const options = { method: 'DELETE', url: api + 'products/hapusprod/' + item.barang_id };

    //     axios.request(options).then(function (response) {
    //         console.log(response.data);
    //         getProduk()

    //     }).catch(function (error) {
    //         console.error(error);
    //     });


    //     // console.log('cart', this.props.cartItems.length);
    // }


    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full mb-12 px-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                            "bg-white"
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center justify-between" >
                                <div className="relative w-full px-4 max-w-full flex-grow  flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            "text-blueGray-700"
                                        }
                                    >
                                        Laporan Penjualan Perbulan
                                    </h3>

                                </div>
                                <input
                                    type={"month"}
                                    onChange={(e) => { setCariBulan(e.target.value); console.log(cariBulan) }}
                                    value={cariBulan}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" />

                                {<button
                                    onClick={onGetJualBulan}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-search"></i> Show
                                </button>}
                                {<button
                                    onClick={getReset}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-sync"></i> Reload
                                </button>}
                                {<button
                                    onClick={handleClick}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-print"></i> Print
                                </button>}

                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                            {/* Projects table */}
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            No Faktur
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Tanggal
                                        </th>

                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Nama Barang
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Harga Pokok
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Harga Jual
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Qty
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            SUB Total
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>

                                    </tr>
                                </thead>



                                <tbody>
                                    {commentsData?.map((item, i) => (
                                        <tr key={i}>

                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.nofak}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.jual_tanggal}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.nama_barang}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {currencyFormat(item.harga_modal)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {currencyFormat(item.harga_jual)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.qty}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {currencyFormat(item.total)}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {/* <button
                                                    onClick={() => handleClickEdit(item)}
                                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-pen"></i> Edit
                                                </button>


                                                <button
                                                    onClick={() => HapusItem(item)}
                                                    className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-trash"></i> Hapus
                                                </button> */}
                                            </td>


                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                            <table>
                                <thead>
                                    <tr>

                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>


                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>

                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Grand Total
                                        </th>

                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        {totaljual?.map((item, i) => (
                                            <th key={i}
                                                className={
                                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                                }
                                            >
                                                {currencyFormat(item.total)}
                                            </th>
                                        ))}
                                        <th
                                            className={
                                                "px-12 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >

                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
                <PaginationComponent
                    total={totalItems}
                    itemsPerPage={pageSize}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </>
    )
}

export default Lapjualbulan