import CardTable from 'components/Cards/CardTable';
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types";
import Api from 'api/Api';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { createPopper } from "@popperjs/core";
import PaginationComponent from 'components/Pagination';
import { useSelector } from 'react-redux';

const Kategori = () => {
    const api = useSelector(state => state.userReducer.level)
    let history = useHistory();
    const [kategori, setKategori] = useState([])
    const [subkat, setSubkat] = useState([])

    const [idsub, setIdsub] = useState('')
    const [text, setText] = useState('')
    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [kat, setKat] = useState('')

    const [searchQuery, setSearchQuery] = React.useState('');

    const [sets, setSets] = useState('')
    const [sets1, setSets1] = useState('')
    const [sets2, setSets2] = useState('')
    const [id, setId] = useState('')

    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loading, setLoading] = useState(false);

    const [gambar, setGambar] = useState('image/jpg')
    const [images, setImages] = useState('')
    const [fileName, setFileName] = useState("placeholder-image.jpg");

    const openMenu = () => setVisible1(true);

    const closeMenu = () => setVisible1(false);


    const pageSize = 10;

    const onChangeSearch = (query) => {

        setSearchQuery(query.id_kategori);
        // if(query.id_kategori == kat){
        //     return
        // }
        console.log('query', JSON.stringify(query.id_kategori))
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'subkategori', sub: query.id_kategori }
            // params: { a: 'subkategori' }
        };

        axios.request(options).then(function (response) {
            // console.log('sub', response.data.data);
            setSubkat(response.data.data)
            setSearchQuery(query.id_kategori);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const [visible, setVisible] = React.useState(false);
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);

    const showDialog = (item) => { setVisible(true); setSets(item); console.log(item) }
    const showDialog1 = (item) => { setVisible1(true); setSets1(item); console.log(item); setText1(item.nama_kategori) }
    const showDialog2 = (item) => { setVisible2(true); setSets2(item); console.log(item); }

    const hideDialog = () => { setVisible(false); setText('') }
    const hideDialog1 = () => { setVisible1(false); setText1('') }
    const hideDialog2 = () => { setVisible2(false); setText2('') }

    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "left",
        });
        setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    useEffect(() => {
        getKategori()
        // getSub()
    }, [])

    const getKategori = () => {

        const options = { method: 'GET', url: api, params: { a: 'kategori' } };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setKategori(response.data.data);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const commentsData = useMemo(() => {
        let computedComments = kategori;

        if (searchQuery) {
            computedComments = computedComments.filter(
                comment =>
                    comment.id_kategori.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }



        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
        );
    }, [kategori, currentPage, searchQuery, sorting]);

    function handleClick() {
        history.push("/admin/kategori/tambah-kat");
    }

    function goEdit(item) {
        history.push("/admin/kategori/edit-kat/" + item.kategori_id);
    }

    // const getSub = () => {
    //     // item.preventDefault();
    //     // openDropdownPopover();
    //     // setId(item.kategori_id)
    //     const options = {
    //         method: 'GET',
    //         url: api,
    //         params: { a: 'subkategori', sub: searchQuery }
    //         // params: { a: 'subkategori' }
    //     };

    //     axios.request(options).then(function (response) {
    //         console.log('sub', response.data.data);
    //         setSubkat(response.data.data)
    //     }).catch(function (error) {
    //         console.error(error);
    //     });
    // }

    const tambahSub = () => {
        // console.log(sets.kategori_id)
        const form = new FormData();
        form.append("id_kategori", sets.id_kategori);
        form.append("id_sub_kategori", idsub);
        form.append("nama_sub_kategori", text);

        const options = {
            method: 'POST',
            url: api,
            params: { a: 'addsubkat' },
            headers: { 'Content-Type': 'multipart/form-data' },
            data: form
        };
        // const options = {
        //     method: 'POST',
        //     url: 'http://localhost/api/kasirapi/api.php',
        //     params: {a: 'addsubkat'},
        //     headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        //     data: {id_kategori: '15', id_sub_kategori: 'fghfgh', nama_sub_kategori: 'fghfgh'}

        //     // method: 'POST',
        //     // url: api,
        //     // params: { a: 'addsubkat' },
        //     // headers: { 'Content-Type': 'application/json' },
        //     // data: { id_kategori: sets.id_kategori, id_sub_kategori: idsub, nama_sub_kategori: text }
        // };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setIdsub('')
            // getSub()
            hideDialog()
        }).catch(function (error) {
            console.error(error);
        });
    }

    const editkategori = () => {
        console.log(sets1.kategori_id)
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'editkat' },
            headers: { 'Content-Type': 'application/json' },
            data: { id_kategori: sets1.id_kategori, nama_kategori: text1 }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            getKategori()
            setText1('')
            hideDialog1()
        }).catch(function (error) {
            console.error(error);
        });
    }

    const editsubkategori = () => {
        console.log(sets2.kategori_id)
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'editsubkat' },
            headers: { 'Content-Type': 'application/json' },
            data: { id_kategori: searchQuery, id_sub_kategori: kat, nama_sub_kategori: text2 }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            // getSub()
            setText2('')
            // getKategori()
            window.location.reload(false)
            hideDialog2()
        }).catch(function (error) {
            console.error(error);
        });
    }

    const HapusSub = (idkat) => {
        console.log('idkat', idkat)
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'hapussubkat' },
            headers: { 'Content-Type': 'application/json' },
            data: { id_sub_kategori: idkat }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            // getSub()

            getKategori()
            window.location.reload(false)

        }).catch(function (error) {
            console.error(error);
        });
    }

    // const removeItemPressed = (item) => {
    //     Alert.alert(
    //         'Hapus ' + item.kategori_nama,
    //         'Apakah Anda Yakin Untuk Menghapus ?',
    //         [
    //             { text: 'Tidak', onPress: () => console.log('No Pressed'), style: 'cancel' },
    //             {
    //                 text: 'Iya', onPress: () => HapusItem(item)
    //             },

    //         ]

    //     );
    // }

    const HapusItem = (item) => {
        console.log(item.id_kategori)
        const options = {
            method: 'POST',
            url: api,
            params: { a: 'hapuskat' },
            headers: { 'Content-Type': 'application/json' },
            data: { id_kategori: item.id_kategori }
        };

        axios.request(options).then(function (response) {
            // console.log(response.data);
            getKategori()

        }).catch(function (error) {
            console.error(error);
        });


        // console.log('cart', item);
    }

    // function HapusSub(sub) {
    //     const options = { method: 'DELETE', url: api + 'categories/tambahsubkat/hapus/' + sub };

    //     axios.request(options).then(function (response) {
    //         // console.log(response.data);
    //         // closeDropdownPopover()
    //         getKategori()
    //         getSub()

    //     }).catch(function (error) {
    //         console.error(error);
    //     });
    //     // console.log('sub', sub.subkat_id);
    // }

    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full mb-12 px-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                            "bg-white"
                        }
                    >
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center justify-between" >
                                <div className="relative w-full px-4 max-w-full flex-grow  flex-1">
                                    <h3
                                        className={
                                            "font-semibold text-lg " +
                                            "text-blueGray-700"
                                        }
                                    >
                                        Data Kategori
                                    </h3>

                                </div>
                                <button onClick={handleClick} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                    <i className="fas fa-plus"></i> Tambah Kategori
                                </button>

                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                            {/* Projects table */}
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Gambar
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Nama
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                            }
                                        >
                                            Action
                                        </th>

                                    </tr>
                                </thead>



                                <tbody>
                                    {commentsData?.map((item) => (
                                        <tr key={item.id_kategori}>
                                            {<th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                                <img
                                                    src={item.gbr}
                                                    className="h-12 w-12 bg-white rounded-full border"
                                                    alt="..."
                                                ></img>{" "}
                                                <span
                                                    className={
                                                        "ml-3 font-bold text-blueGray-600 "
                                                        // +(color === "light" ? "text-blueGray-600" : "text-white")
                                                    }
                                                >

                                                </span>
                                            </th>}

                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {item.nama_kategori}
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {/* <button onClick={() => goEdit(item)} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-pen"></i> Edit
                                                </button> */}
                                                <button onClick={() => showDialog(item)} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-plus"></i> Tambah Sub
                                                </button>





                                                <button onClick={() => HapusItem(item)} className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-trash"></i> Hapus Kategori
                                                </button>

                                                <button onClick={() => showDialog1(item)} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-pen"></i> Edit Kategori
                                                </button>

                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                                                <button
                                                    // ref={btnDropdownRef}
                                                    onClick={() => onChangeSearch(item)}

                                                    // className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button">

                                                    <select onChange={(e) => setKat(e.target.value)} className="border-0 px-6 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                                        <option value=''>lihat SubKategori</option>
                                                        {subkat?.map((sub, i) => (
                                                            <option key={i} value={sub.id_sub_kategori}>{item.id_kategori == sub.id_kategori ? sub.nama_sub_kategori : null}</option>
                                                            // <>
                                                            //     {item.id_kategori == sub.id_kategori ?
                                                            //         <option key={i} value={sub.id_sub_kategori}> {sub.nama_sub_kategori}</option> : null
                                                            //     }

                                                            // </>
                                                        ))}
                                                    </select>

                                                    {/* <i className="fas fa-eye"></i> Lihat Sub */}
                                                </button>
                                                {item.id_kategori == searchQuery && kat != '' ?
                                                    <>
                                                        <button onClick={() => { showDialog2(kat) }} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                            <i className="fas fa-pen"></i> Edit Sub
                                                        </button>
                                                        <button onClick={() => HapusSub(kat)} className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                            <i className="fas fa-trash"></i> Hapus Sub
                                                        </button>

                                                    </> : null
                                                }
                                                <button onClick={() => window.location.reload(false)} className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-sync"></i>
                                                </button>
                                            </td>


                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                        </div>
                    </div>
                    <PaginationComponent
                        total={totalItems}
                        itemsPerPage={pageSize}
                        currentPage={currentPage}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                {visible ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        // onClick={hideDialog}
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                            Tambah  subKategori
                                        </h6>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={hideDialog}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    {/* <div className="flex-auto  px-4 lg:px-10 py-10 pt-0">
                                        <div className="mb-3 mt-3 pt-0">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                ID Sub
                                            </label>
                                            <input value={idsub} onChange={(e) => setIdsub(e.target.value)} type="text" placeholder="ID Sub kategori" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full" />
                                        </div>
                                    </div> */}
                                    <div className="flex-auto  px-4 lg:px-10 py-10 pt-0">
                                        <div className="mb-3 mt-3 pt-0">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Nama Sub
                                            </label>
                                            <input value={text} onChange={(e) => setText(e.target.value)} type="text" placeholder="Sub kategori" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full" />
                                        </div>
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={hideDialog}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => tambahSub()}
                                        >
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}

                {visible1 ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        // onClick={hideDialog}
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                            Edit Kategori
                                        </h6>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={hideDialog1}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}

                                    <div className="flex-auto  px-4 lg:px-10 py-10 pt-0">
                                        <div className="mb-3 mt-3 pt-0">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Nama Kategori
                                            </label>
                                            <input value={text1} onChange={(e) => setText1(e.target.value)} type="text" placeholder="Nama kategori" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full" />
                                        </div>
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={hideDialog1}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={editkategori}
                                        >
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}

                {visible2 ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        // onClick={hideDialog}
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                            Edit Sub Kategori {searchQuery}
                                        </h6>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={hideDialog2}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}

                                    <div className="flex-auto  px-4 lg:px-10 py-10 pt-0">
                                        <div className="mb-3 mt-3 pt-0">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Nama Sub Kategori
                                            </label>
                                            <input value={text2} onChange={(e) => setText2(e.target.value)} type="text" placeholder="Nama kategori" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full" />
                                        </div>
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"

                                            onClick={hideDialog2}

                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={editsubkategori}
                                        >
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}

            </div>
        </>
    )
}

CardTable.defaultProps = {
    color: "light",
};

CardTable.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};

export default Kategori