import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const TambahProduk = () => {
    let history = useHistory();
    let ids = useParams();
    const api = useSelector(state => state.userReducer.level)
    const [kategori, setKategori] = useState([])
    const [subkat, setSubkat] = useState([])
    const [sets, setSets] = useState(false)
    const [id, setId] = useState('')
    const [kodebarang, setkodebarang] = useState('')
    const [namabarang, setnamabarang] = useState('')
    const [merek, setmerek] = useState('')
    const [id_kategori, setid_kategori] = useState('0')
    const [unit, setunit] = useState('')
    const [harga_modal, setharga_modal] = useState('')
    const [harga_jual, setharga_jual] = useState('')
    const [stok, setstok] = useState('')
    const [min_stok, setMin_stok] = useState('1')
    const [diskon, setDiskon] = useState('')
    const [diskonNominal, setDiskonNominal] = useState('')
    const [foto, setfoto] = useState()
    const [id_sub_kategori, setid_sub_kategori] = useState('0')
    const [berhasil, setBerhasil] = useState('Upload')

    const [gambar, setGambar] = useState('image/jpg')
    const [images, setImages] = useState()
    const [fileName, setFileName] = useState("placeholder-image.jpg");
    const [preview, setPreview] = useState('')
    const [loading, setLoading] = useState(false);

    const [produk, setProduk] = useState([])
    const [select, setSelect] = useState('')


    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });


    const [searchQuery, setSearchQuery] = React.useState('');


    const onChangeSearch = (query) => {
        setSearchQuery(query.kategori_nama);
    }

    const pageSize = 100;
    console.log('ids', ids.id);
    useEffect(() => {
        getKategori()
        getProdukId()
        getSub()
    }, [])

    const getKategori = () => {

        const options = { method: 'GET', url: api, params: { a: 'kategori' } };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setKategori(response.data.data);
        }).catch(function (error) {
            console.error(error);
        });
    }


    const getProdukId = () => {
        if (ids.id == undefined) {
            setSets(false)

        } else {
            setSets(true)
            const options = {
                method: 'GET',
                url: api,
                params: { a: 'barangid', id: ids.id }
            };

            axios.request(options).then(function (response) {
                console.log('barangid', response.data.data);
                // setProduk(response.data.response)
                setId(response.data.data[0].id_barang)
                setkodebarang(response.data.data[0].kode_barang)
                setnamabarang(response.data.data[0]?.nama_barang)
                setmerek(response.data.data[0]?.merek)
                setharga_jual(response.data.data[0]?.harga_jual)
                setharga_modal(response.data.data[0]?.harga_modal)
                setstok(response.data.data[0]?.stok)
                setMin_stok(response.data.data[0]?.stok_min)
                setunit(response.data.data[0]?.unit)
                setSearchQuery(response.data.data[0]?.id_kategori)
                setid_sub_kategori(response.data.data[0]?.id_sub_kategori)
                setDiskon(response.data.data[0]?.diskon)
                setDiskonNominal(response.data.data[0]?.diskon_nominal)
                // setGambar('image/jpg')
                // setSearchQuery(response.data.response[0]?.kategori_nama)
                // getSub()
                setPreview(response.data.data[0]?.foto)
                // setFileName(response.data.data[0]?.foto)
            }).catch(function (error) {
                console.error(error);
            });
        }

    }

    const getSub = () => {
        // item.preventDefault();
        // openDropdownPopover();
        // setId(item.kategori_id)
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'subkategori', sub: searchQuery }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setSubkat(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
    }

    // const getSub = (item) => {
    //     // item.preventDefault();
    //     dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
    //     setId(item.kategori_id)
    //     const options = { method: 'GET', url: api + 'categories/subkat/' + item.kategori_id };

    //     axios.request(options).then(function (response) {
    //         // console.log(response.data);
    //         setSubkat(response.data.response)
    //     }).catch(function (error) {
    //         console.error(error);
    //     });
    // }

    useEffect(() => {
        if (!images) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(images)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [images])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImages(undefined)
            return
        }
        console.log(e.target.files[0])
        // I've kept this example simple by using the first image instead of multiple
        setImages(e.target.files[0])
    }

    const onSelectFileExcel = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setfoto(undefined)
            return
        }
        console.log(e.target.files[0])
        // I've kept this example simple by using the first image instead of multiple
        setfoto(e.target.files[0])
    }

    function handleClick() {
        history.goBack();
    }

    const onKat = e => {

        setSearchQuery(e.target.value);
        console.log(e.target.value)
        const options = {
            method: 'GET',
            url: api,
            params: { a: 'subkategori', sub: e.target.value }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setSubkat(response.data.data)
        }).catch(function (error) {
            console.error(error);
        });
        // const options = { method: 'GET', url: api + 'categories/subkat/' + e.target.value };

        // axios.request(options).then(function (response) {
        //     console.log(response.data);
        //     setSubkat(response.data.response)
        // }).catch(function (error) {
        //     console.error(error);
        // });
    }

    // const commentsData = useMemo(() => {
    //     let computedComments = subkat;

    //     if (searchQuery) {
    //         computedComments = computedComments.filter(
    //             comment =>
    //                 comment.kategori_nama.toLowerCase().includes(searchQuery.toLowerCase())
    //         );
    //     }
    //     setkat(computedComments[0]?.sub_kategori_id)
    //     // setkate(e.target.value)
    //     console.log(computedComments[0]?.sub_kategori_id)

    //     setTotalItems(computedComments.length);

    //     //Sorting comments
    //     if (sorting.field) {
    //         const reversed = sorting.order === "asc" ? 1 : -1;
    //         computedComments = computedComments.sort(
    //             (a, b) =>
    //                 reversed * a[sorting.field].localeCompare(b[sorting.field])
    //         );
    //     }

    //     //Current Page slice
    //     return computedComments.slice(
    //         (currentPage - 1) * pageSize,
    //         (currentPage - 1) * pageSize + pageSize
    //     );
    // }, [subkat, currentPage, searchQuery, sorting]);

    // const onKate = (e) => {
    //     setkate(e.target.value)
    //     console.log(e.target.value)
    // }

    const postProduk = () => {
        // if (kodebarang.length === 0 || namabarang.length === 0 || id_kategori.length === 0 || satuan.length === 0 || harpok.length === 0 || harjul.length === 0 || stok.length === 0) {
        //     return
        // }
        setLoading(true)
        // const form = new FormData();
        // form.append("fileData", images);
        const form = new FormData();
        form.append("kode_barang", kodebarang);
        form.append("nama_barang", namabarang);
        form.append("merek", merek);
        form.append("harga_modal", Number(harga_modal));
        form.append("harga_jual", Number(harga_jual));
        form.append("stok", Number(stok));
        form.append("image", images);
        form.append("unit", unit);
        form.append("diskon", diskon);
        form.append("diskon_nominal", diskonNominal);
        form.append("id_sub_kategori", id_sub_kategori);
        form.append("id_kategori", searchQuery);



        const options = {
            method: 'POST',
            url: api,
            params: { a: 'addproduct' },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form
        };
        console.log('form', form);
        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.ok == false) {
                setLoading(false)

                return
            } else {
                setLoading(false)
                // setText('')
                history.goBack()
            }
        }).catch(function (error) {
            console.error(error);
        });
        // console.log('gbr', gambar.uri)
        // const formdata = new FormData();
        // formdata.append("kobar", kobar);
        // formdata.append("nabar", nabar);
        // formdata.append("satuan", satuan);
        // formdata.append("harpok", Number(harpok));
        // formdata.append("harjul", Number(harjul));
        // formdata.append("harjul_grosir", Number(harjul_grosir));
        // formdata.append("stok", Number(stok));
        // formdata.append("min_stok", Number(min_stok));
        // formdata.append("kat", kat);
        // formdata.append("kate", kate);
        // formdata.append("user_id", 1);
        // formdata.append("fileData", images);
        // const config = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     body: formdata,
        // };
        // fetch(api + "products/tambahprod", config)
        //     .then((checkStatusAndGetJSONResponse) => {
        //         console.log(checkStatusAndGetJSONResponse);
        //         if (checkStatusAndGetJSONResponse.ok == false) {
        //             setLoading(false)
        //             return
        //         } else {
        //             setLoading(false)
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //         setLoading(false)
        //     });

    }

    const PostMysql = () => {
        const form = new FormData();
        form.append("filexls", foto);

        const options = {
            method: 'POST',
            url: api,
            params: { a: 'excel' },
            headers: { 'Content-Type': 'multipart/form-data' },
            data: form
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.Pesan || response.data.err) {
                setBerhasil(response.data.Pesan || response.data.err)
            } else {
                setBerhasil('Gagal!, harap pilih file')
            }

        }).catch(function (error) {
            console.error(error);
        });
    }

    const editProduk = () => {
        const form = new FormData();
        form.append("id_barang", id);
        form.append("kode_barang", kodebarang);
        form.append("nama_barang", namabarang);
        form.append("merek", merek);
        form.append("harga_modal", Number(harga_modal));
        form.append("harga_jual", Number(harga_jual));
        form.append("stok", Number(stok));
        form.append("image", images);
        form.append("unit", unit);
        form.append("diskon", diskon);
        form.append("diskon_nominal", diskonNominal);
        form.append("id_sub_kategori", id_sub_kategori);
        form.append("id_kategori", searchQuery);

        const options = {
            method: 'POST',
            url: api,
            params: { a: 'editproduct' },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form
        };
        console.log('form', form);
        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.ok == false) {
                setLoading(false)
                return
            } else {
                setLoading(false)
                // setText('')
                history.goBack()
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    const onDiskon = (event) => {
        var nominal = parseInt(harga_jual) * (event / 100);
        setDiskon(event)
        setDiskonNominal(nominal)
        console.log('hasil', event)
    }


    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <button onClick={handleClick}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-arrow-left"></i>  Kembali
                        </button>

                        {sets ?
                            <button onClick={editProduk}
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button"
                            >
                                <i className="fas fa-check"></i> Simpan Edit
                            </button>
                            :
                            <>

                                <div className="relative">

                                    <label htmlFor="upload" className=" bg-red-500 cursor-pointer text-white active:bg-green-600 font-bold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" tabIndex={0}>
                                        <span className="cursor-pointer">Pilih File Excel</span>
                                        {/* <i className="fas fa-file-upload" /> */}
                                        <input onChange={onSelectFileExcel} type="file" id="upload" className="account-file-input" hidden accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
                                        <i className="fas fa-file-upload px-2"></i>
                                    </label>
                                    {/* <input
                                        placeholder='input'
                                        type="file"
                                        hidden
                                        onChange={onSelectFileExcel}
                                        className="border-0 px-3   placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring  ease-linear transition-all duration-150"

                                    /> */}
                                    <button onClick={PostMysql}
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        <i className="fas fa-check"></i> {berhasil}
                                    </button>
                                    <a href='https://marinamuslim.com/sampel_produk.xlsx'
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        <i className="fas fa-download"></i> Download Sampel
                                    </a>
                                </div>


                                <button onClick={postProduk}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                >
                                    <i className="fas fa-check"></i> Simpan
                                </button>
                            </>
                        }
                    </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <div className="w-full lg:w-12/12 px-4">

                        </div>
                        <div className="flex mt-3  mb-6 flex-wrap">

                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Photo
                                    </label>
                                    <input
                                        type="file"
                                        onChange={onSelectFile}
                                        className="border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // defaultValue="lucky.jesse"
                                    />
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-6/12 sm:w-4/12 px-4">
                                            {images ? <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" /> : <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" />}

                                        </div>


                                    </div>
                                </div>
                            </div>



                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Kode Barang
                                    </label>
                                    <input
                                        disabled={sets}
                                        type="text"
                                        value={kodebarang}
                                        onChange={(e) => setkodebarang(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Nama Barang
                                    </label>
                                    <input
                                        type="text"
                                        value={namabarang}
                                        onChange={(e) => setnamabarang(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Merek
                                    </label>
                                    <input
                                        type="text"
                                        value={merek}
                                        onChange={(e) => setmerek(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Unit
                                    </label>
                                    <input
                                        type="text"
                                        value={unit}
                                        onChange={(e) => setunit(e.target.value)}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Kategori
                                    </label>
                                    <select value={searchQuery} onChange={onKat} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option>Pilih Kategori</option>
                                        {kategori?.map((item, i) => (
                                            <option key={i} value={item.id_kategori}    >{item.nama_kategori}</option>
                                        ))}


                                    </select>

                                </div>
                            </div>

                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        SubKategori
                                    </label>
                                    <select onClick={getSub} value={id_sub_kategori} onChange={(e) => setid_sub_kategori(e.target.value)} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <option>Pilih SubKategori</option>
                                        {subkat?.map((sub, i) => (
                                            <option key={i} value={sub.id_sub_kategori}>{sub.nama_sub_kategori}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Harga Pokok
                                    </label>
                                    <input
                                        type="number"
                                        value={harga_modal}
                                        onChange={(e) => setharga_modal(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Harga Jual
                                    </label>
                                    <input
                                        type="number"
                                        value={harga_jual}
                                        onChange={(e) => setharga_jual(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        name={select}
                                        value={select}
                                        // id="customCheckLogin"
                                        type="radio"

                                        onChange={e => setSelect('1')}
                                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    />
                                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                                        Diskon Persen
                                    </span>
                                </label>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        name={select}
                                        value={select}
                                        // id="customCheckLogin"
                                        type="radio"
                                        // disabled={true}
                                        onChange={() => setSelect('2')}
                                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    />
                                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                                        Diskon Nominal
                                    </span>
                                </label>
                                {select == '' ?

                                    <input
                                        type="number"
                                        disabled={select == '' && true}
                                        value={diskon == '' ? diskonNominal : diskon}
                                        // onChange={(e) => onDiskon(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    /> : null}

                                {select == '1' ?
                                    <input
                                        type="number"
                                        inputMode="numeric"
                                        disabled={select == '' && true}
                                        value={diskon}
                                        onChange={(e) => onDiskon(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    /> : null}
                                {select == '2' ?
                                    <input
                                        type="number"
                                        inputMode="numeric"
                                        disabled={select == '' && true}
                                        value={diskonNominal}
                                        onChange={(e) => setDiskonNominal(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    /> : null}


                            </div>
                            {/* <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Diskon Persen
                                    </label>
                                    <input
                                        type="number"
                                        value={diskon}
                                        onChange={(e) => onDiskon(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div> */}
                            {/* <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Diskon Nominal
                                    </label>
                                    <input
                                        type="number"
                                        value={diskonNominal}
                                        onChange={(e) => setDiskonNominal(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div> */}
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Stok Barang
                                    </label>
                                    <input
                                        type="number"
                                        value={stok}
                                        onChange={(e) => setstok(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Min Stok Barang
                                    </label>
                                    <input
                                        type="number"
                                        value={min_stok}
                                        onChange={(e) => setMin_stok(e.target.value)} placeholder="0"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"

                                    />
                                </div>
                            </div>




                        </div>

                        <hr className="mt-6 border-b-1 border-blueGray-300" />

                        {/* <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Contact Information
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="email"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="New York"
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Country
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="United States"
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Postal Code
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="Postal Code"
                                    />
                                </div>
                            </div>
                        </div>

                        <hr className="mt-6 border-b-1 border-blueGray-300" />

                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            About Me
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        About me
                                    </label>
                                    <textarea
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        defaultValue="A beautiful UI Kit and Admin for React & Tailwind CSS. It is Free and Open Source."
                                        rows="4"
                                    ></textarea>
                                </div>
                            </div>
                        </div> */}
                    </form>
                </div>
            </div>
        </>
    )
}

export default TambahProduk