import Api from 'api/Api';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TambahKat = () => {
    let history = useHistory();
    const api = useSelector(state => state.userReducer.level)
    const [text, setText] = useState('')
const [idkategori,setIdkateg]=useState('')
    const [searchQuery, setSearchQuery] = React.useState('');

    const [sets, setSets] = useState(false)
    const [id, setId] = useState('')

    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loading, setLoading] = useState(false);

    const [gambar, setGambar] = useState('image/jpg')
    const [images, setImages] = useState()
    const [fileName, setFileName] = useState("placeholder-image.jpg");
    const [preview, setPreview] = useState('')

    function handleClick() {
        history.goBack();
    }
    useEffect(() => {
        if (!images) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(images)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [images])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImages(undefined)
            return
        }
        console.log(e.target.files[0])
        // I've kept this example simple by using the first image instead of multiple
        setImages(e.target.files[0])
    }

    const postKategori = () => {
        if (text.length === 0) return
        console.log('Gambar', images);
        // Create an object of formData
        const form = new FormData();
        form.append("id_kategori", idkategori);
        form.append("image", images);
        form.append("nama_kategori", text);

        const options = {
            method: 'POST',
            url: api,
            params: {a: 'addkat'},
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form
        };
        console.log('form', form);
        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.ok == false) {
                setLoading(false)
                return
            } else {
                setLoading(false)
                setText('')
                history.goBack()
            }
        }).catch(function (error) {
            console.error(error);
        });
        // setLoading(true)
        // console.log('gbr', gambar)
        // const data = new FormData();
        // data.append('nama_kategori', text);
        // data.append('fileData', images);
        // const config = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     body: data,
        // };
        // fetch(api + "categories/tambahkat", config)
        //     .then((checkStatusAndGetJSONResponse) => {
        //         console.log(checkStatusAndGetJSONResponse);
        //         if (checkStatusAndGetJSONResponse.ok == false) {
        //             setLoading(false)
        //             return
        //         } else {
        //             setLoading(false)
        //             setText('')
        //             history.goBack()
        //         }
        //     }).catch((err) => { console.log(err) });
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <button onClick={handleClick}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-arrow-left"></i>  Kembali
                        </button>
                        <button onClick={postKategori}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-check"></i> Simpan
                        </button>
                    </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Tambah Kategori
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">

                                    <input
                                        type="file"
                                        onChange={onSelectFile}
                                        className="border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // defaultValue="lucky.jesse"
                                    />
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-6/12 sm:w-4/12 px-4">
                                            {images && <img src={preview} alt="gbr" className="shadow rounded max-w-full h-auto align-middle border-none" />}

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4"></div>
                            {/* <div className="w-full lg:w-6/12 px-4"> */}
                                {/* <div className="relative w-full mb-3"> */}
                                    {/* <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ID Kategori
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setIdkateg(e.target.value)}
                                        placeholder='ID Kategori'
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // defaultValue="jesse@example.com"
                                    /> */}
                                {/* </div> */}
                            {/* </div> */}
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Nama
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setText(e.target.value)}
                                        placeholder='nama'
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // defaultValue="jesse@example.com"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TambahKat