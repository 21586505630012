
import Api from 'api/Api';
import axios from 'axios';
import React, { useRef, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import moment from "moment";
import { useParams } from 'react-router-dom';

const Printjualhari = () => {
    const lap = useSelector(state => state.lapbulanReducer)
    const user = useSelector(state => state.userReducer.user)
    const api = useSelector(state => state.userReducer.level)
    const [date, setDate] = useState('')
    const [totaljual, setTotaljual] = useState([])
    const componentRef = useRef();
    let ids = useParams();
    const [transaksi, setTransaksi] = useState([])
    console.log('laph', ids.id)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        // getKategori()
        // getProduk()

        // getTransaksi()
        getTotaltanggal()
        getTransaksi()
    }, [])
    const getTotaltanggal = () => {

        const options = {
            method: 'GET',
            url: api,
            params: { a: 'getTotalJUal', tgl: moment(ids.id).format('YYYY-MM-DD') }
        };

        axios.request(options).then(function (response) {
            console.log(response.data.data);

            setTotaljual(response.data.data)

        }).catch(function (error) {
            console.error(error);
        })
    }

    const getTransaksi = () => {

        const options = {
            method: 'GET',
            url: api,
            params: { a: 'gettransaksi', tgl: moment(ids.id).format('YYYY-MM-DD') }
        };

        axios.request(options).then(function (response) {
            console.log(response.data.data);

            setTransaksi(response.data.data)

        }).catch(function (error) {
            console.error(error);
        })
    }

    function currencyFormat(num) {
        return 'Rp ' + parseFloat(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    // const RenderItem = () => {
    //     let items = [];
    //     lap.laphari?.map((item, i) => {
    //         // const newTotal = item.quantity * item.harga;
    //         items.push(
    //             <tr key={i}>
    //                 <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
    //                     {item.no_faktur}
    //                 </th>
    //                 <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    //                     {item.nama}
    //                 </td>
    //                 <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    //                     {moment(item.tanggal).format('DD-MM-YYYY')}
    //                 </td>
    //                 <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    //                     {item.jumlah}
    //                 </td>
    //                 <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    //                     {item.status}
    //                 </td>
    //                 <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    //                     {item.admin}
    //                 </td>
    //                 <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    //                     {item.total}
    //                 </td>
    //             </tr>
    //         );
    //     });

    //     return items;
    // }

    return (
        <>

            <div
                className={
                    "relative  bg-blueGray-100 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    "light"
                }
            >
                <div className="w-full mt-1 lg:w-6/12 px-4">
                    <button onClick={handlePrint} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6  rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                        Print

                    </button>

                </div>
                {/* //pribnt */}
                {/* <ComponantPrint ref={componentRef} /> */}
                <div ref={componentRef} className="block w-full  overflow-x-auto">
                    {/* <div className="rounded-t mt-10   border-blueGray-100 mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3
                                    className={
                                        "font-semibold text-lg " +
                                        "light"
                                    }
                                >
                                    {user[0]?.username == 'admin1' && 'UD.AFGANROOF 1'}
                                    {user[0]?.username == 'admin2' && 'UD.AFGANROOF 2'}
                                    {user[0]?.username == 'owner' && 'UD.AFGANROOF'}

                                </h3>
                            </div>
                        </div>
                    </div> */}


                    <table className="items-center mt-1 w-full border-collapse">
                        <thead>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    TOKO ABC
                                </th>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                ></th>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                            </tr>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Alamat : Jl. SURGA
                                </th>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                ></th>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                            </tr>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Telepon : -
                                </th>
                                <th className={
                                    "px-6 align-middle   text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                ></th>

                                <th className={
                                    "px-6 align-middle text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                            </tr>
                            <tr>

                                <th
                                    className={
                                        "px-6 align-middle text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Tanggal : {moment(ids.id).format('DD MMMM YYYY')}
                                </th>

                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }>

                                </th>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                ></th>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                            </tr>
                            <tr>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }>   </th>
                                <th className={
                                    "px-6 align-middle text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }></th>
                                <th
                                    className={
                                        "px-6 align-middle  text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                ></th>
                                <th className={
                                    "px-6 align-middle  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                }>  </th>
                            </tr>
                            <tr></tr>
                        </thead>
                    </table>
                    {/* Projects table */}
                    <table className="items-center mt-3 w-full border-collapse">
                        <thead>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    No Faktur
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Nama Barang
                                </th>

                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Qty
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Harga Jual
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Admin
                                </th>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >
                                    Sub Total
                                </th>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +

                                        "bg-blueGray-50 text-blueGray-500 border-blueGray-100"

                                    }
                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>
                            {transaksi?.map((item, i) => (
                                <tr key={i}>

                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.nofak}
                                    </td>

                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.nama_barang}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.qty}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {currencyFormat(item.harga_jual)}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.nama}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {currencyFormat(item.total)}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {/* <button
                                                    onClick={() => handleClickEdit(item)}
                                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-pen"></i> Edit
                                                </button>


                                                <button
                                                    onClick={() => HapusItem(item)}
                                                    className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                                    <i className="fas fa-trash"></i> Hapus
                                                </button> */}
                                    </td>


                                </tr>
                            ))}


                        </tbody>
                       
                    </table>
                    <table className="items-center mt-3 w-full border-collapse">
                        <tfoot>
                            <tr>
                                {/* <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">

                                    <span
                                        className={
                                            "ml-3 font-bold " +
                                            +"light"
                                        }
                                    >
                                        Total Laba :
                                    </span>
                                </th> */}
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                                </td> <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                                </td> <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                                </td>
                                <th className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">

                                    <span
                                        className={
                                            "ml-3 font-bold " +
                                            +"light"
                                        }
                                    >
                                        Grand Total  :
                                    </span>
                                </th>


                                {totaljual?.map((item, i) => (
                                    <th
                                        className={
                                            "border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"

                                        }
                                    >
                                        {currencyFormat(item.total)}
                                    </th>
                                ))}
                            </tr>
                        </tfoot>

                    </table>

                </div>


            </div>

        </>
    )
}

export default Printjualhari