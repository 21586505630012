import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Kategori from "views/admin/Kategori";
import TambahKat from "views/admin/TambahKat";
import EditKat from "views/admin/EditKat";
import Produk from "views/admin/Produk";
import Karyawan from "views/admin/Karyawan";
import TambahProduk from "views/admin/TambahProduk";
import TambahKaryawan from "views/admin/TambahKaryawan";
import LaporanJual from "views/admin/LaporanJual";
import LaporanLaba from "views/admin/LaporanLaba";
import Penjualan from "views/admin/Penjualan";
import Lapjualbulan from "views/admin/Lapjualbulan";
import Printjualhari from "views/admin/Printjualhari";
import Printjualbulan from "views/admin/Printjualbulan";
import PrintLabaBulan from "views/admin/PrintLabaBulan";
import Retur from "views/admin/Retur";
import DetRetur from "views/admin/DetRetur";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/kategori" exact component={Kategori} />
            <Route path="/admin/kategori/tambah-kat" exact component={TambahKat} />
            <Route path="/admin/kategori/edit-kat/:id" exact component={EditKat} />
            <Route path="/admin/produk" exact component={Produk} />
            <Route path="/admin/karyawan" exact component={Karyawan} />
            <Route path="/admin/retur" exact component={Retur} />
            <Route path="/admin/retur/det-order/:id" exact component={DetRetur} />
            <Route path="/admin/produk/tambah-produk" exact component={TambahProduk} />
            <Route path="/admin/karyawan/tambah-karyawan" exact component={TambahKaryawan} />
            <Route path="/admin/karyawan/edit-karyawan/:id" exact component={TambahKaryawan} />
            <Route path="/admin/produk/edit-produk/:id" exact component={TambahProduk} />
            <Route path="/admin/laporanjual" exact component={LaporanJual} />
            <Route path="/admin/lapjualbulan" exact component={Lapjualbulan} />
            <Route path="/admin/laporanlaba" exact component={LaporanLaba} />
            <Route path="/admin/penjualan" exact component={Penjualan} />
            <Route path="/admin/laporanjual/laporan-hari/:id" exact component={Printjualhari} />
            <Route path="/admin/lapjualbulan/laporan-bulan/:id" exact component={Printjualbulan} />
            <Route path="/admin/laporanlaba/laporan-laba/:id" exact component={PrintLabaBulan} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
